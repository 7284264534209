import React, { FC } from "react"
import { Button, ButtonGroup, Menu, Position } from "@blueprintjs/core"
import { Popover2 } from "@blueprintjs/popover2"

import { useApi } from "src/hooks/useApi"
import { FileUpload, FileUploadMode } from "./FileUpload/FileUpload"

export const NcProgramUpload: FC<{
  planId: string
  operationIdx: number
  locked?: boolean
}> = ({ planId, operationIdx, locked }) => {
  const { planchangerApi } = useApi()

  return (
    <ButtonGroup>
      <FileUpload
        fileUploadMode={FileUploadMode.NcProgramUpload}
        tooltip={"Drag and drop the main NC program here. You can also click to select."}
        label={"NC file"}
        onUpload={file => planchangerApi.ncProgram(planId, operationIdx, file)}
        extensionWhitelist={[".nc", ".mpf", "*.txt"]}
        locked={locked}
      />
      <Popover2
        position={Position.BOTTOM}
        disabled={locked}
        content={
          <Menu>
            <CamProjectUpload planId={planId} operationIdx={operationIdx} />
          </Menu>
        }
      >
        <Button icon={"chevron-down"} disabled={locked} />
      </Popover2>
    </ButtonGroup>
  )
}

export const CamProjectUpload: FC<{
  planId: string
  operationIdx: number
}> = ({ planId, operationIdx }) => {
  const { filesApi } = useApi()

  return (
    <FileUpload
      fileUploadMode={FileUploadMode.CamProjectUpload}
      tooltip={"Drag and drop the CAM project here to back it up. You can also click to select."}
      label={"CAM project"}
      onUpload={file => filesApi.createDocumentFile(planId, operationIdx, file)}
      extensionBlacklist={[".txt", ".nc", ".mpf"]}
    />
  )
}

export const VericutProjectUpload: FC<{
  planId: string
  operationIdx: number
}> = ({ planId, operationIdx }) => {
  const { filesApi } = useApi()

  return (
    <FileUpload
      fileUploadMode={FileUploadMode.VericutProjectUpload}
      tooltip={
        "Drag and drop a Vericut project here to update the operation. You can also click to select."
      }
      label={"Vericut project"}
      onUpload={file =>
        filesApi.importVericutProject(planId, operationIdx, file).then(res => {
          console.log(res)
        })
      }
      extensionWhitelist={[".zip"]}
    />
  )
}
