import create, { GetState, SetState } from "zustand"
import { StoreApiWithSubscribeWithSelector, subscribeWithSelector } from "zustand/middleware"

import {
  BaseStockOnlyFeature,
  DatumReferenceFrame,
  FieldToolResult,
  HasBaseStockFeature,
  MissingIntersectionsFeature,
  MultitoolFeature,
  PointlessFeature,
  SchemaConstructedFeature,
  SchemaDim,
  SchemaFeature,
  SchemaFeatureCircle,
  SchemaFeatureCone,
  SchemaFeatureConstructedBestFitCylinder,
  SchemaFeatureConstructedCircle,
  SchemaFeatureConstructedSphere,
  SchemaFeatureCylinder,
  SchemaFeatureLineSegment,
  SchemaFeatureSlot,
  SchemaFeatureSphere,
  SchemaFeatureUnknown,
  SchemaFeatureWidth,
  SchemaField,
  SuccessFeature,
} from "src/client-axios/api"
import { PcdmisExtractorInfo, PcdmisSimAnalysis } from "src/graphql/generated"

export type PcdmisExtractorInfoData = Pick<
  PcdmisExtractorInfo,
  | "extractedDate"
  | "inspectedDate"
  | "program"
  | "sku"
  | "unit"
  | "pcdmisExtractorManifestUri"
  | "skuJsonUri"
  | "unitJsonUri"
  | "updatedAt"
  | "id"
>

export type PcdmisSimAnalysisData = Pick<
  PcdmisSimAnalysis,
  | "unit"
  | "sku"
  | "simManifestUri"
  | "pcdmisExtractorManifestUri"
  | "id"
  | "fieldResult"
  | "updatedAt"
>

export type FieldToolResultFeature =
  | PointlessFeature
  | MissingIntersectionsFeature
  | BaseStockOnlyFeature
  | MultitoolFeature
  | HasBaseStockFeature
  | SuccessFeature

export type SchemaFeatureUnion =
  | SchemaConstructedFeature
  | SchemaFeature
  | SchemaFeatureCircle
  | SchemaFeatureConstructedCircle
  | SchemaFeatureCone
  | SchemaFeatureConstructedBestFitCylinder
  | SchemaFeatureCylinder
  | SchemaFeatureLineSegment
  | SchemaFeatureSlot
  | SchemaFeatureSphere
  | SchemaFeatureConstructedSphere
  | SchemaFeatureUnknown
  | SchemaFeatureWidth

export interface UnitProgram {
  unit: string
  program: string
}

export interface CmmSelectionData {
  skuDims: Array<SchemaDim>
  unitDims: Array<SchemaDim>
  skuFeature: SchemaFeatureUnion
  unitFeature: SchemaFeatureUnion
  featureToolResult: FieldToolResultFeature
  pointIdx: number
}

export function pcdmisSimAnalysisFieldResult(
  pcdmisSimAnalysis: PcdmisSimAnalysisData
): FieldToolResult {
  return (pcdmisSimAnalysis.fieldResult as unknown) as FieldToolResult
}

export type CmmDisplayState = {
  simManifestUri?: string
  // function of current job
  pcdmisExtractorInfos?: Array<PcdmisExtractorInfoData>
  // setting by user (dependent upon simManifestUri)
  currentUnitProgram?: UnitProgram
  // functions of currentUnit
  pcdmisSimAnalysis?: PcdmisSimAnalysisData
  skuSchemaField?: SchemaField
  unitSchemaField?: SchemaField
  // UI state
  selectedFeatures: Array<string>
  selectedSkuDims: Array<SchemaDim>
  selectedUnitDims: Array<SchemaDim>
  selectedDrfs: Array<DatumReferenceFrame>
  clickSelection?: CmmSelectionData
}

export function defaultCmmDisplayState(): CmmDisplayState {
  return {
    simManifestUri: undefined,
    pcdmisExtractorInfos: undefined,
    currentUnitProgram: undefined,
    pcdmisSimAnalysis: undefined,
    skuSchemaField: undefined,
    unitSchemaField: undefined,
    selectedFeatures: [],
    selectedSkuDims: [],
    selectedUnitDims: [],
    selectedDrfs: [],
    clickSelection: undefined,
  }
}

export const useCmmDisplayStore = create<
  CmmDisplayState,
  SetState<CmmDisplayState>,
  GetState<CmmDisplayState>,
  StoreApiWithSubscribeWithSelector<CmmDisplayState>
>(subscribeWithSelector((): CmmDisplayState => defaultCmmDisplayState()))

export function resetStateForEmptyUnitSelectionOrSimManifestUri(state: CmmDisplayState): void {
  selectFeatures(state, [], true)
}

export function selectFeature(state: CmmDisplayState, feature: string): void {
  const set = new Set(state.selectedFeatures)
  if (!set.has(feature)) {
    set.add(feature)
    useCmmDisplayStore.setState({
      selectedFeatures: [...set.values()],
    })
  }
}

export function getFeatureDimensions(
  schemaField: SchemaField | undefined,
  features: Array<string>
): Array<SchemaDim> {
  if (schemaField === undefined) {
    return []
  }

  const featureNames = new Set(features)
  return schemaField.dims.filter(dim => dim.features.findIndex(name => featureNames.has(name)) >= 0)
}

function dimDatumsName(datums: Array<string>): string {
  return datums.join("|")
}

export function getDimensionDrfs(
  state: CmmDisplayState,
  dims: Array<SchemaDim>
): Array<DatumReferenceFrame> {
  const pcdmisSimAnalysis = state.pcdmisSimAnalysis
  if (pcdmisSimAnalysis === undefined) {
    return []
  }

  const dimNames = new Set(dims.map(dim => dim.name).filter(v => !!v))
  const dimDrfNames = new Set(
    dims.filter(dim => !!dim.datums).map(dim => dimDatumsName(dim.datums))
  )
  const fieldResult = pcdmisSimAnalysisFieldResult(pcdmisSimAnalysis)
  return fieldResult.drfs.filter(drf => {
    if (drf.ref_dim) {
      return dimNames.has(drf.ref_dim)
    }
    return dimDrfNames.has(dimDatumsName(drf.datums))
  })
}

function updateFromFeatureSet(state: CmmDisplayState, set: Set<string>) {
  const selectedFeatures = [...set.values()]
  const selectedSkuDims = getFeatureDimensions(state.skuSchemaField, selectedFeatures)
  const selectedUnitDims = getFeatureDimensions(state.unitSchemaField, selectedFeatures)
  const selectedDrfs = getDimensionDrfs(state, selectedSkuDims)
  useCmmDisplayStore.setState({
    selectedFeatures,
    selectedSkuDims,
    selectedUnitDims,
    selectedDrfs,
  })
}

export function selectFeatures(
  state: CmmDisplayState,
  features: string[],
  clearExisting: boolean
): void {
  const existing = clearExisting ? [] : state.selectedFeatures
  let changed = existing.length !== state.selectedFeatures.length
  const set = new Set(existing)
  features.forEach(feature => {
    if (!set.has(feature)) {
      set.add(feature)
      changed = true
    }
  })

  if (changed) {
    updateFromFeatureSet(state, set)
  }
}

export function deselectFeature(state: CmmDisplayState, feature: string): void {
  const set = new Set(state.selectedFeatures)
  if (set.delete(feature)) {
    updateFromFeatureSet(state, set)
  }
}

export function toggleFeature(state: CmmDisplayState, feature: string): void {
  const set = new Set(state.selectedFeatures)
  if (set.has(feature)) {
    set.delete(feature)
  } else {
    set.add(feature)
  }

  updateFromFeatureSet(state, set)
}
